@import url(https://fonts.googleapis.com/css2?family=Comfortaa&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: "Comfortaa", cursive;
  /* margin: 0; */
  /* padding: 0; */
}

/* .App {
  text-align: center;
  margin: 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.navigationContainer {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.432);
  /* margin: 0; */
  padding-top: 20px;
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.152);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px) grayscale(100%);
  top: 0;
}

.navSeparator {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  list-style: none;
  -webkit-text-decoration-style: none;
          text-decoration-style: none;
  color: gray;
  padding: 0;
  margin: 0;
}

li {
  text-decoration: none;
  -webkit-text-decoration-style: none;
          text-decoration-style: none;
  padding: 10px;
}

.nav_link {
  text-decoration: none;
  color: black;
}

.nav_link:hover {
  color: gray;
}

.linksBar {
  display: flex;
  align-items: center;
  /* background-color: green; */
  align-content: center;
  margin-right: 10px;
}

.cardContainer {
  position: relative;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.438);
  width: 300px;
  margin: 10px;
  padding: 10px;
  border-radius: 25px;
  overflow: hidden;
}
.background_image {
  background-position: center;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 5px;
  padding-top: 5px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(50, 50, 50, 0.543);
}

.contentArea {
  text-align: left;
}

.companyLabel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.githubLogo:hover {
  color: grey;
}

.followers_box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.userName {
  text-align: center;
  margin: 0;
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline;
}

.company_location {
  margin: 0px;
  padding: 0px;
}

.image_background {
  /* display: inline; */
  padding: 0;
  width: 150%;
  height: 150%;
  bottom: -9px;
  left: -10px;

  -webkit-filter: brightness(110%) blur(10px);

          filter: brightness(110%) blur(10px);
  /* filter: blur(10px); */
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* z-index: 0; */
}

.contentBox {
  position: relative;
  background-color: rgba(255, 255, 255, 0.399);
  padding: 5px;
  border-radius: 15px;
  box-shadow: inset 0px 0px 100px white;
}

/* TwoColImgDesc styling */

.mainContainer {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 0px 15px black; */
  border-bottom: 1px solid rgba(135, 135, 135, 0.454);
}

.descriptionContainer {
  width: 30vw;
  text-align: left;
  margin-left: 15px;
  line-height: 20px;
}

.imageContainer {
}

.imageContainer > img {
  width: 50vw;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 660px) {
  .mainContainer {
    margin: 0;
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .descriptionContainer {
    margin-top: 0px !important;
    width: 90%;
    text-align: left;

    /* padding: 5px; */
    margin: 0;
    /* margin-left: 15px; */
  }

  .imageContainer {
  }

  .imageContainer > img {
    width: 100vw;
    padding: 0;
    margin: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .descriptionContainer {
    margin-top: 65px;
  }
}

.mainIconContainer {
  padding: 20px;
  align-items: center;
  align-content: center;
  text-align: center;
  border-bottom: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}
.iconContainer {
  margin: 5px;
  font-size: 12pt;
}

@media only screen and (max-width: 660px) {
  .mainIconContainer {
    padding: 5px;
    align-items: center;
    align-content: center;
    text-align: center;
    border-bottom: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .iconContainer {
    margin: 15px;
  }
}

