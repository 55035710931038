.navigationContainer {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.432);
  /* margin: 0; */
  padding-top: 20px;
  position: fixed;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.152);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(5px) grayscale(100%);
  top: 0;
}

.navSeparator {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  list-style: none;
  text-decoration-style: none;
  color: gray;
  padding: 0;
  margin: 0;
}

li {
  text-decoration: none;
  text-decoration-style: none;
  padding: 10px;
}

.nav_link {
  text-decoration: none;
  color: black;
}

.nav_link:hover {
  color: gray;
}

.linksBar {
  display: flex;
  align-items: center;
  /* background-color: green; */
  align-content: center;
  margin-right: 10px;
}
