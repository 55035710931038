/* TwoColImgDesc styling */

.mainContainer {
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 0px 15px black; */
  border-bottom: 1px solid rgba(135, 135, 135, 0.454);
}

.descriptionContainer {
  width: 30vw;
  text-align: left;
  margin-left: 15px;
  line-height: 20px;
}

.imageContainer {
}

.imageContainer > img {
  width: 50vw;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 660px) {
  .mainContainer {
    margin: 0;
    margin-top: 100px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .descriptionContainer {
    margin-top: 0px !important;
    width: 90%;
    text-align: left;

    /* padding: 5px; */
    margin: 0;
    /* margin-left: 15px; */
  }

  .imageContainer {
  }

  .imageContainer > img {
    width: 100vw;
    padding: 0;
    margin: 0;
  }
}

@media only screen and (max-width: 1100px) {
  .descriptionContainer {
    margin-top: 65px;
  }
}
