.cardContainer {
  position: relative;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.438);
  width: 300px;
  margin: 10px;
  padding: 10px;
  border-radius: 25px;
  overflow: hidden;
}
.background_image {
  background-position: center;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 5px;
  padding-top: 5px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(50, 50, 50, 0.543);
}

.contentArea {
  text-align: left;
}

.companyLabel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.githubLogo:hover {
  color: grey;
}

.followers_box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
.userName {
  text-align: center;
  margin: 0;
  text-decoration-line: underline;
}

.company_location {
  margin: 0px;
  padding: 0px;
}

.image_background {
  /* display: inline; */
  padding: 0;
  width: 150%;
  height: 150%;
  bottom: -9px;
  left: -10px;

  filter: brightness(110%) blur(10px);
  /* filter: blur(10px); */
  position: absolute;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* z-index: 0; */
}

.contentBox {
  position: relative;
  background-color: rgba(255, 255, 255, 0.399);
  padding: 5px;
  border-radius: 15px;
  box-shadow: inset 0px 0px 100px white;
}
